@import '../../../theme/variables.scss';

.user-specific-page {
    height: 100%;
    .btn {
        --bs-btn-padding-x: 0px;
        --bs-btn-active-border-color: transparent;
    }
    &__button {
        height: 66px;
        width: 100%;
        gap: 10px;
        align-items: center;
    }
    &__content {
        height: 100%;
        gap: 10px;
    }
    .body2 {
        margin-bottom: 0px;
    }
}
