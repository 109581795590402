@import '../../../theme/variables.scss';
@import '../../../theme/scroll.scss';

.table-skeleton {
    height: 100%;
    width: 100%;
    min-width: 72.75rem;

    > header {
        flex: none !important;
        height: 5rem;
        padding: 0rem 1rem;
        background-color: $structural-CardBG;
    }

    .rdt_TableHeader {
        background-color: $structural-CardBG;
        min-height: min-content;
        max-height: 6.25rem;
        > div > p {
            color: $text-high-emphasis;
            margin: 0%;
            margin-top: 1.25rem;
            text-transform: uppercase;
        }
    }

    .rdt_Table {
        background-color: $structural-CardBG;
        > :nth-child(2):not(.rdt_TableBody) {
            flex-grow: 1;
            background-color: transparent;
        }
    }

    .rdt_TableBody {
        > .rdt_TableRow:nth-child(2n) {
            background-color: $vizualization-zebra-row-purple;
        }
    }

    .table-subheader {
        > .btn-group {
            height: 2.25rem !important;
            min-width: 8.125rem;
        }
    }

    .page-item > button:not(:disabled) {
        color: $text-high-emphasis;
    }

    .page-item > button:disabled {
        color: $text-low-emphasis !important;
    }

    .page-link {
        width: 1.5rem;
    }

    &__arrow {
        cursor: pointer;
    }

    .disabled {
        color: $text-low-emphasis !important;
    }

    .active > .page-link {
        box-shadow: none;
        border: 1px solid $primary-500 !important;
        color: $text-high-emphasis !important;
    }

    .rdt_TableCell {
        color: $text-high-emphasis !important;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        .avatar {
            padding: 0% !important;
            div {
                display: grid;
            }
        }
        p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: normal !important;
        }
    }

    .rdt_TableCol {
        background-color: $primary-500;
        color: $text-black !important;
    }

    &__pagination {
        height: 3.25rem;
        border-radius: 0rem 0rem 0.625rem 0.625rem;
        border-top: 1px solid $structural-stroke-100 !important;
    }

    .rdt_TableCol_Sortable {
        display: flex;
        align-items: center;
        overflow: visible;
        position: relative;

        > span.__rdt_custom_sort_icon__ svg {
            color: $vizualization-sort-purple;
            display: none;
            position: absolute;
            right: 0;
            top: 30%;
        }

        &:hover {
            opacity: 1;
            > span.__rdt_custom_sort_icon__ svg {
                display: block;
            }
        }

        .body2 {
            margin: 0%;
        }
    }

    .sorted-column {
        .body2 {
            font-weight: 800;
        }
    }

    .rdt_TableHeadRow {
        border: none !important;
        min-height: 2.5rem !important;
        background-color: $primary-500;
    }

    .rdt_TableRow {
        min-height: 2.5rem !important;
        background-color: transparent;

        border-top: 0.5px solid transparent !important;
        border-bottom: 0.5px solid transparent !important;

        &:hover {
            background-color: $table-row-hover !important;
            border-bottom: 0.5px solid $vizualization-border-purple !important;
            border-top: 0.5px solid $vizualization-border-purple !important;
        }

        .engagement-title:hover > .body5 {
            color: $primary-500 !important;
        }

        > .sorted-column-cell {
            .body5 {
                font-weight: 800;
            }
        }

        .body5 {
            margin: 0%;
        }
    }

    .rows-options {
        .body4 {
            margin: 0%;
        }
        .dropdown-toggle {
            min-width: 3.688rem !important;
        }
    }

    .table-row-menu {
        min-width: 3.688rem !important;
        .table-row-item {
            &:hover {
                background-color: $primary-500 !important;
                color: $body-color !important;
            }
        }
    }
    .subtitle3 {
        margin-bottom: 0rem;
    }

    &__engage-button {
        border: 1px solid $primary-500 !important;
        border-radius: 0.25rem;
        width: 5.5rem;
        color: $primary-500;
        background-color: $structural-stroke-500;
    }
}

.sub-table {
    width: 100%;
    .body5 {
        width: 16.875rem;
    }
}
