@import '../../../theme/variables.scss';

.tooling-tooltip {
    z-index: 1100;
    width: fit-content;
    .tooltip-inner {
        background-color: $text-medium-emphasis !important;
        display: flex;
        justify-content: flex-start !important;
        min-width: fit-content !important;
    }

    .overline2,
    .subtitle4 {
        padding-bottom: 0rem;
    }
    .tooltip-arrow {
        display: none;
    }
    &__content {
        width: fit-content;
        height: fit-content;
    }
}
