@import '../../../theme/variables.scss';

.findings-table {
    width: 100%;
    padding-right: 0rem !important;
    &__engagement-cell-header {
        gap: 0.6rem;
    }

    .opacity-transparent {
        opacity: 0.3;
    }

    .opacity-1 {
        opacity: 1;
    }

    &__engagement-cell {
        min-width: 140px;
        gap: 0.6rem;
        &__icon {
            height: 1rem;
            width: 1rem;
        }
    }
    .body5 {
        margin-bottom: 0px;
    }
    .hgByPU div:first-child {
        overflow: visible;
    }

    .provider_logo {
        width: 2em;
        height: 2rem;
        padding: 0.25rem;
    }
    &__svg-react-icon path {
        stroke: $secondary-accent-red-2 !important;
    }
}

.tooltip-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-width: 42rem;
    padding: 0.6rem 0.6rem 0 0.6rem;
    text-align: justify;
}